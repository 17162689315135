import React from 'react'
import styled from 'styled-components'
import { Text, Flex, Heading, IconButton, ArrowBackIcon, NotificationDot } from '@pancakeswap/uikit'
import { useExpertModeManager } from 'state/user/hooks'
import GlobalSettings from 'components/Menu/GlobalSettings'
import Link from 'next/link'
import Transactions from './Transactions'
import QuestionHelper from '../QuestionHelper'

import Rule from './Rules/index'

interface Props {
  title: string
  subtitle: string
  helper?: string
  backTo?: string
  noConfig?: boolean
  showRule?: boolean
}

const AppHeaderContainer = styled(Flex)`
  flex-direction: column;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const AppHeader: React.FC<Props> = ({ title, subtitle, helper, backTo, noConfig = false, showRule = false }) => {
  const [expertMode] = useExpertModeManager()

  return (
    <AppHeaderContainer>
      <Flex alignItems="center" mr={noConfig ? 0 : '16px'}>
        {backTo && (
          <Link passHref href={backTo}>
            <IconButton as="a">
              <ArrowBackIcon width="32px" />
            </IconButton>
          </Link>
        )}
        <Flex justifyContent="space-between" alignItems="center" style={{ flex: 1 }}>
          <Heading as="h2" mb="0px" style={{ flex: 1 }}>
            {title}
          </Heading>
          {/* 隐藏交易历史 & 全局设置 */}
          {!noConfig && (
            <Flex alignItems="center">
              <NotificationDot show={expertMode}>
                <GlobalSettings />
              </NotificationDot>
              <Transactions />
              {showRule && <Rule />}
            </Flex>
          )}
        </Flex>
      </Flex>

      <Flex>
        <Text color="textSubtle" fontSize="14px">
          {subtitle}
        </Text>
        <Flex alignItems="center">
          {helper && <QuestionHelper size="20px" text={helper} ml="6px" placement="top-start" />}
        </Flex>
      </Flex>
    </AppHeaderContainer>
  )
}

export default AppHeader
